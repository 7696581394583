const cgtFromSalary = (value) => {
  switch (true) {
    case value > 190000:
      return 0.45;
    case value <= 190000 && value > 135000:
      return 0.37;
    case value <= 135000 && value > 45000:
      return 0.3;
    case value <= 45000 && value > 18200:
      return 0.16;
    default:
      return 0;
  }
};

const retirementTable = (result, scenarioSelected) => {
  let interestRate =
    Number(scenarioSelected?.purchaseTargets[0]?.interestRate) / 100;
  const annualIncrease = 0.05;
  let salaryAtTime = scenarioSelected?.totalPrimaryIncome;
  let costOfAssets = 0;

  return result?.map((one, index) => {
    const {
      value,
      rentPerMonth,
      debtRemainingValue,
      grossCFValue,
      equityValue,
    } = one;

    if (index) salaryAtTime += (salaryAtTime * annualIncrease) / 12;
    if (one.isBought) {
      scenarioSelected?.purchaseTargets.forEach((purchaseTarget) => {
        if (one.date.format("MM/DD/YYYY") === purchaseTarget.purchaseDate) {
          costOfAssets += purchaseTarget.propertyPrice;
          interestRate = Number(purchaseTarget.interestRate) / 100;
        }
      });
    }

    const incomePA = rentPerMonth * 12;
    const lvr = debtRemainingValue / value;
    const grossYield = grossCFValue / costOfAssets;
    const interestPM = (debtRemainingValue * interestRate) / 12;
    const netCFValue = grossCFValue - grossCFValue * 0.25 - interestPM;
    const netYield = netCFValue / costOfAssets;
    const cgt = cgtFromSalary(salaryAtTime);

    return {
      costOfAssets,
      value,
      incomePA,
      debtRemainingValue,
      lvr: lvr * 100,
      grossYield: grossYield * 100,
      grossCashFlowPM: grossCFValue,
      interestPM,
      netCFValue,
      netYield: netYield * 100,
      salaryAtTime,
      cgt,
      equityValue,
      interestRate,
    };
  });
};

const calcPortfolioValue = (retirementValue, portfoliovalue) => {
  const {
    costOfAssets,
    value,
    debtRemainingValue,
    grossCashFlowPM,
    cgt,
    equityValue,
    interestRate,
  } = retirementValue;

  const sellingAmount = value - portfoliovalue < 0 ? 0 : value - portfoliovalue;
  const reduceBy = sellingAmount / value;
  const costOfCGTOnSell =
    sellingAmount - costOfAssets < 0
      ? 0
      : ((sellingAmount - costOfAssets) / 2) * cgt;
  const totalCostOfSell = sellingAmount + costOfCGTOnSell;
  const debtRemaining =
    debtRemainingValue - sellingAmount < 0
      ? 0
      : debtRemainingValue - sellingAmount;
  const lvr = debtRemaining <= 0 ? 0 : debtRemaining / portfoliovalue;
  const loanPayment = (debtRemaining * interestRate) / 12;
  const grossCashFlow = grossCashFlowPM * (1 - reduceBy);
  const netCashFlow = grossCashFlow - grossCashFlow * 0.25 - loanPayment;
  const grossYield =
    (grossCashFlow * 12) / (costOfAssets - costOfAssets * reduceBy);
  const netYield =
    (netCashFlow * 12) / (costOfAssets - costOfAssets * reduceBy);
  const liquidity =
    sellingAmount - debtRemainingValue < 0
      ? 0
      : sellingAmount - debtRemainingValue;

  return {
    value,
    sellingAmount,
    reduceBy: reduceBy * 100,
    costOfCGTOnSell,
    portfoliovalue,
    totalCostOfSell,
    debtRemaining,
    lvr: lvr * 100,
    loanPayment,
    grossCashFlow,
    netCashFlow,
    grossYield,
    netYield,
    liquidity,
    equityValue: portfoliovalue * (1 - lvr),
  };
};

const calcGrossCFValue = (retirementValue, grossCFValue) => {
  const {
    costOfAssets,
    value,
    debtRemainingValue,
    grossCashFlowPM,
    cgt,
    equityValue,
    interestRate,
  } = retirementValue;

  const reduceBy = 1 - grossCFValue / grossCashFlowPM;
  const sellingAmount = reduceBy * value;
  const costOfCGTOnSell =
    sellingAmount - costOfAssets < 0
      ? 0
      : ((sellingAmount - costOfAssets) / 2) * cgt;
  const totalCostOfSell = sellingAmount + costOfCGTOnSell;
  const portfoliovalue = value - sellingAmount;
  const debtRemaining =
    debtRemainingValue - sellingAmount < 0
      ? 0
      : debtRemainingValue - sellingAmount;
  const lvr = debtRemaining <= 0 ? 0 : debtRemaining / portfoliovalue;
  const loanPayment = (debtRemaining * interestRate) / 12;
  const netCashFlow = grossCFValue - grossCFValue * 0.25 - loanPayment;
  const grossYield =
    (grossCFValue * 12) / (costOfAssets - costOfAssets * reduceBy);
  const netYield =
    (netCashFlow * 12) / (costOfAssets - costOfAssets * reduceBy);
  const liquidity =
    sellingAmount - debtRemainingValue < 0
      ? 0
      : sellingAmount - debtRemainingValue;

  return {
    value,
    sellingAmount,
    reduceBy: reduceBy * 100,
    costOfCGTOnSell,
    portfoliovalue,
    totalCostOfSell,
    debtRemaining,
    lvr: lvr * 100,
    loanPayment,
    grossCashFlow: grossCFValue,
    netCashFlow,
    grossYield,
    netYield,
    liquidity,
    equityValue: portfoliovalue * (1 - lvr),
  };
};

export { retirementTable, calcPortfolioValue, calcGrossCFValue };
