import { StackActions, NavigationActions } from "react-navigation";

let navigator;

export function setTopLevelNavigator(navigatorRef) {
  navigator = navigatorRef;
}

export const resetStack = (screen_name) =>
  StackActions.reset({
    index: 0,
    actions: [NavigationActions.navigate({ routeName: screen_name })],
  });

export const resetStackWithKey = (screen_name, key) =>
  StackActions.reset({
    index: 0,
    key: "MyAccount",
    actions: [NavigationActions.navigate({ routeName: screen_name })],
  });

export const resetStackWithPassingInitialData = (screen_name) =>
  StackActions.reset({
    index: 0,
    actions: [
      NavigationActions.navigate(
        { routeName: screen_name },
        { INITIAL_SCREEN: "YES" }
      ),
    ],
  });

export const logout = () => {
  if (navigator) {
    navigator.dispatch(
      NavigationActions.navigate({
        routeName: "GuestScreen",
        action: NavigationActions.navigate({ routeName: "LoginScreen" }),
      })
    );
  }
};
