import React, { memo, useState, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  Image,
} from "react-native";
import { MAIN_COLORS } from "../../../Utility/Colors";
import { MAIN_FONT } from "../../../Utility/Fonts/font";
import { CheckListItem } from "../../../Utility/ComponentCheckList/CheckListItem/indexv2";

const ChecklistReport = ({ title, task }) => {
  const reportText =
    task?.["Progress"]?.replace(/\t/g, " ").replace(/ +/g, " ").trim() || "";
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const lengthChecked = pages.filter((item) => item.resolved === true).length;
  const itemsPerPage = 3;

  useEffect(() => {
    if (reportText && title) {
      const extractedText = extractSectionContent(reportText, title);
      const parsedPages = parsePages(extractedText);
      setPages(parsedPages);
      setCurrentPage(0);
    }
  }, [reportText, title]);

  const totalPages = Math.ceil(pages.length / itemsPerPage);
  const paginatedItems = pages.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const changePage = (direction) => {
    if (direction === "prev" && currentPage > 0) {
      setCurrentPage(currentPage - 1);
    } else if (direction === "next" && currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (pages.length === 1 && pages?.[0]?.name?.trim() === "") {
    return null;
  }

  console.log({ currentPage, totalPages });

  return (
    <View>
      <Text style={styles.sectionTitle}>{title ?? ""}</Text>
      <View style={styles.reportContainer}>
        <View style={styles.paginationHeader}>
          <TouchableOpacity
            onPress={() => changePage("prev")}
            style={[styles.pageButton, { opacity: currentPage > 0 ? 1 : 0 }]}
            pointerEvents={currentPage > 0 ? "auto" : "none"}
          >
            <Image
              source={require("../../../assets/angle-left.svg")}
              resizeMode="contain"
              style={styles.icon}
            />
          </TouchableOpacity>

          <Text style={styles.paginationText}>
            {lengthChecked} / {pages.length}
          </Text>

          <TouchableOpacity
            onPress={() => changePage("next")}
            style={[
              styles.pageButton,
              { opacity: currentPage < totalPages - 1 ? 1 : 0 },
            ]}
            pointerEvents={currentPage < totalPages - 1 ? "auto" : "none"}
          >
            <Image
              source={require("../../../assets/angle-right.svg")}
              resizeMode="contain"
              style={styles.icon}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.pagesContainer}>
          {paginatedItems?.map((item, index) => (
            <CheckListItem key={index} item={item} />
          ))}
        </View>
      </View>
    </View>
  );
};

const extractSectionContent = (text, sectionTitle) => {
  const regexSection = new RegExp(
    `\\b${sectionTitle.replace(/\s+/g, '\\s*')}:?\\b`,
  );
  const match = text.match(regexSection);
  const updatedText = text.replace(regexSection, sectionTitle);

  if (!match) return '';
  const startIndex = match.index;
  let endIndex = updatedText.length;
  if (sectionTitle === 'Building Report') {
    const pestRegex = new RegExp(`\\bPest Report:?\\b`);
    const pestMatch = updatedText.match(pestRegex);
    if (pestMatch) {
      endIndex = pestMatch.index;
    }
  }
  return updatedText.substring(startIndex, endIndex).trim();
};

const parsePages = (extractedText) => {
  const createCombinedObj = (pageTitle, pageContent) => {
    const combined =
      pageTitle && pageTitle.length > 0
        ? `${pageTitle}\n${pageContent}`
        : pageContent;
    return { name: combined, resolved: true };
  };

  if (extractedText.startsWith("Pest Report")) {
    let pestContent = extractedText.replace(/^Pest Report:?\s*/, "").trim();
    pestContent = pestContent.replace(/"$/, "").trim();

    if (/Page\s+\d/.test(pestContent)) {
      const pageSegments = pestContent.split(/(?=Page\s+\d)/);
      const pages = pageSegments.map((segment) => {
        const match = segment.match(/^(Page\s+[\d\-, &]+:?)/);
        let pageTitle = "";
        let pageContent = "";
        if (match) {
          pageTitle = match[1].trim();
          pageContent = segment.replace(match[1], "").trim();
        } else {
          pageContent = segment.trim();
        }
        pageContent = pageContent.replace(/"$/, "").trim();
        return createCombinedObj(pageTitle, pageContent);
      });
      return pages;
    } else {
      const sentences = pestContent
        .split(/\.+/)
        .map((sentence) => sentence.trim() + ".")
        .filter((sentence) => sentence.length > 1);
      const pages = sentences.map((sentence) =>
        createCombinedObj("", sentence)
      );
      return pages;
    }
  }

  if (extractedText.startsWith("Building Report")) {
    let buildingContent = extractedText
      .replace(/^Building Report:?\s*/, "")
      .trim();
    buildingContent = buildingContent.replace(/"$/, "").trim();
    const pageSegments = buildingContent.split(/(?=Page\s+\d)/);
    const pages = pageSegments.map((segment) => {
      const match = segment.match(/^(Page\s+[\d\-, &]+:?)/);
      let pageTitle = "";
      let pageContent = "";
      if (match) {
        pageTitle = match[1].trim();
        pageContent = segment.replace(match[1], "").trim();
      } else {
        pageContent = segment.trim();
      }
      pageContent = pageContent.replace(/"$/, "").trim();
      return createCombinedObj(pageTitle, pageContent);
    });
    return pages;
  }

  return [createCombinedObj("", extractedText.replace(/"$/, "").trim())];
};

const styles = StyleSheet.create({
  sectionTitle: {
    fontSize: 16,
    color: "#FFFFFF",
    fontFamily: MAIN_FONT.semi,
    marginBottom: 16,
  },
  reportContainer: {
    flexDirection: "column",
    padding: 12,
    borderRadius: 10,
    gap: 16,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
  },
  paginationHeader: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  pageButton: {
    alignItems: "center",
    justifyContent: "center",
    width: 28,
    height: 28,
    borderRadius: 32,
    backgroundColor: "#120F0E",
  },
  icon: {
    height: 10,
    width: 5,
  },
  paginationText: {
    fontSize: 14,
    color: "#FFFFFF",
    fontFamily: MAIN_FONT.semi,
  },
  pagesContainer: {
    flexDirection: "column",
    paddingHorizontal: 8,
    paddingVertical: 16,
    gap: 16,
    borderRadius: 8,
    backgroundColor: "#120F0E",
  },
  pageContainer: {
    borderWidth: 1,
    borderColor: "#ccc",
    padding: 10,
    marginBottom: 12,
    borderRadius: 5,
  },
  pageTitle: {
    fontSize: 18,
    fontWeight: "600",
    marginBottom: 8,
    color: "#FFFFFF",
  },
  paragraph: {
    fontSize: 16,
    color: "#FFFFFF",
  },
});

export default memo(ChecklistReport);
